.login_wrap {
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}

.login_form {
    display: flex;
    justify-content: center;
    margin: auto 0;
    align-items: center;
    height: 100vh;
}

#login-form {
    width: 360px;
}

#logo_img {
    width: 150px;
    padding-bottom: 40px;
}

label {
    display: flex !important;
    font-weight: 600;
    padding-bottom: 4px;
    color: #ffff;
    font-family: 'Proxima Nova';
    font-size: 16px;
}

.login_btn {
    font-weight: 600;
    color: #ffff;
    font-family: 'Proxima Nova';
    font-size: 18px;
}

input[type="email"],
input[type="password"],
input[type="text"] {
    background-color: #0000;
    border-color: #CFCFCF;
    color: #ffff;
}

input:-webkit-autofill {
    -webkit-background-clip: text;
}

div.loginpass input:-webkit-autofill {
    -webkit-text-fill-color: #ffff !important;
}

input#login-mail {
    -webkit-text-fill-color: white;
}

input#login-password {
    -webkit-text-fill-color: white;
}



input[type="email"]:focus,
input[type="password"]:focus,
input[type="text"]:focus {
    box-shadow: none;
    background-color: #0000;
    border-color: #CFCFCF;
    color: #ffff;
}

.forgot {
    display: flex;
    justify-content: flex-end;
    width: 100% !important;
}

.forgot a {
    font-family: 'Proxima Nova';
    color: #ffff;
    font-size: smaller;
    text-decoration: none;
}

.forgot a:hover {
    color: #ffff;
}

.sub {
    width: 100% !important;
    background-color: #FE7300 !important;
    text-decoration-color: #ffff !important;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none !important;
    font-size: 18px !important;
}

.reset {
    cursor: pointer;
    color: #ffff;
}

.passwordText {
    display: flex !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.375rem !important;
}

@media screen and (max-width: 768px) {
    #login-form {
        width: 80%;
    }

}

@media (min-width: 768px) and (max-width: 992px) {
    #login-form {
        width: 40%;
    }

}

#form-password{
    color: white;
}